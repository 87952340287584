import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { Icon } from "@material-ui/core";

import { BlockStructureType } from "common/constants";
import { app } from "js/namespaces";
import { reactMount, reactUnmount } from "js/react/renderReactRoot";
import * as geom from "js/core/utilities/geom";
import { $, _ } from "js/vendor";
import { themeColors } from "js/react/sharedStyles";

const Container = styled.div.attrs(({ isDragging }) => ({
    style: {
        cursor: isDragging ? "grab" : "unset",
        pointerEvents: isDragging ? "all" : "unset"
    }
}))`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
`;

const BlockContainer = styled.div.attrs(({ bounds, boundsScale }) => ({
    style: {
        ...bounds.scale(1 / boundsScale).toObject(),
        transform: `scale(${boundsScale})`
    }
})
)`
  position: absolute;
`;

const MenuWidgetBox = styled.div.attrs(({ blockFrameBounds }) => ({
    style: {
        ...new geom.Rect(blockFrameBounds.left - 25, blockFrameBounds.top - 1, 25, blockFrameBounds.height + 2).toObject()
    }
})
)`
  color: ${themeColors.ui_blue};
  font-size: 11px;
  text-transform: uppercase;
  padding: 6px 10px 6px 4px;
  position: absolute;
  display: flex;
  align-items: center;
  background: ${themeColors.contextBlue};

  .material-icons {
    font-size: 16px;
    margin-right: 10px;
    pointer-events: none;
  }
`;

const BlockFrame = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.toObject() }
})
)`
  position: absolute;
  border: dotted 1px ${themeColors.ui_blue50percent};

  div {
    pointer-events: none !important;
  }
`;

const TextElementDropTarget = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.toObject() }
})
)`
  position: absolute;
  border-bottom: 3px solid ${themeColors.ui_blue};
`;

const CustomDropTargetContainer = styled.div.attrs(({ bounds, isActive }) => ({
    style: {
        ...bounds.toObject(),
        background: isActive ? themeColors.ui_blue : themeColors.ui_blue50percent
    }
})
)`
  position: absolute;
  border: 1px solid ${themeColors.ui_blue};
`;

const CustomDropTargetLabel = styled.div`
  position: absolute;
  left: 50%;
  top: -4px;
  transform: translate(-50%, -100%);

  font-size: 12px;
  text-transform: uppercase;
  color: ${themeColors.ui_blue};
`;

function CustomDropTarget({ bounds, labelText, isActive }) {
    return (<CustomDropTargetContainer bounds={bounds} isActive={isActive}>
        <CustomDropTargetLabel>{labelText}</CustomDropTargetLabel>
    </CustomDropTargetContainer>);
}

export class DragBlockManager {
    constructor(selectionLayer, $el) {
        this.selectionLayer = selectionLayer;
        this.canvas = selectionLayer.currentCanvas;

        this.$el = this.selectionLayer.$el.append($.div());

        this.renderRollover();
    }

    dispose() {
        reactUnmount(this.$el[0]);
        this.$el.remove();
    }

    renderRollover(dragState = null) {
        reactMount(<DragBlockManagerRollover canvas={this.canvas} dragState={dragState} onDragEnd={() => this.renderRollover()} />, this.$el[0], true);
    }

    registerDrag(sourceAuthoringBlockEditor, sourceElement, sourceBlocks, rolloverBlock) {
        this.renderRollover({
            sourceAuthoringBlockEditor,
            sourceElement,
            sourceBlocks,
            rolloverBlock
        });
    }
}

export class DragBlockManagerRollover extends Component {
    static defaulDragState = {
        isDragging: false,
        draggingBlocks: [],
        customDropTargets: [],
        activeCustomDropTargetId: null,
        blockContainerBounds: null,
        blockContainerBoundsDragOffset: null,
        dropTargetBounds: null,
        dropElement: null,
        dropBlock: null,
        dropBlockIndex: null
    }

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();

        this.state = { ...DragBlockManagerRollover.defaulDragState };
    }

    componentDidMount() {
        const { dragState } = this.props;
        if (dragState) {
            this.startDrag();
        }
    }

    componentDidUpdate(prevProps) {
        const { dragState } = this.props;

        if (!prevProps.dragState && dragState) {
            this.startDrag();
        }
    }

    startDrag() {
        const {
            dragState: {
                sourceAuthoringBlockEditor,
                sourceElement,
                sourceBlocks,
                rolloverBlock
            }
        } = this.props;

        app.isDragging = true;
        app.isDraggingItem = true;

        sourceAuthoringBlockEditor.setState({ isDragging: true });

        for (const block of sourceBlocks) {
            block.setState({ isDragging: true });
        }

        const draggingBlocks = sourceBlocks.map(sourceBlock => ({
            isRolloverBlock: sourceBlock === rolloverBlock,
            frameBounds: sourceBlock.props.renderProps.bounds,
            blockHTML: sourceBlock.containerRef.current.parentElement.innerHTML
        }));

        const containerBounds = geom.Rect.FromBoundingClientRect(this.containerRef.current.getBoundingClientRect());
        let blockContainerBounds = geom.Rect.FromBoundingClientRect(sourceBlocks[0].props.containerRef.current.getBoundingClientRect());
        const blockContainerBoundsDragOffset = new geom.Point(event.pageX - blockContainerBounds.left, event.pageY - blockContainerBounds.top);
        blockContainerBounds = blockContainerBounds.offset(-containerBounds.left, -containerBounds.top);

        const canvasBounds = geom.Rect.FromBoundingClientRect(sourceElement.canvas.el.getBoundingClientRect());
        const customDropTargets = sourceElement.getRootElement().getCustomBlocksDropTargets(sourceElement, sourceBlocks)
            .map(target => ({
                ...target,
                bounds: target.canvasBounds
                    .multiply(sourceElement.canvas.canvasScale)
                    .offset(canvasBounds.left, canvasBounds.top)
                    .offset(-containerBounds.left, -containerBounds.top),
                screenBounds: target.canvasBounds
                    .multiply(sourceElement.canvas.canvasScale)
                    .offset(canvasBounds.left, canvasBounds.top)
            }));

        this.setState({
            isDragging: true,
            customDropTargets,
            draggingBlocks,
            blockContainerBounds,
            blockContainerBoundsDragOffset
        });

        document.addEventListener("mousemove", this.handleDrag);
        document.addEventListener("mouseup", this.handleDragEnd);
    }

    handleDrag = event => {
        const { dragState: { sourceElement, sourceBlocks } } = this.props;
        const { blockContainerBoundsDragOffset, blockContainerBounds, customDropTargets } = this.state;

        const containerBounds = geom.Rect.FromBoundingClientRect(this.containerRef.current.getBoundingClientRect());
        const updatedBlockContainerBounds = blockContainerBounds.setPosition(
            new geom.Point(event.pageX, event.pageY).offset(-containerBounds.left, -containerBounds.top).offset(-blockContainerBoundsDragOffset.x, -blockContainerBoundsDragOffset.y)
        );

        const dragPoint = new geom.Point(event.pageX, event.pageY);

        let activeCustomDropTargetId;
        for (const dropTarget of customDropTargets) {
            if (dropTarget.screenBounds.contains(dragPoint)) {
                activeCustomDropTargetId = dropTarget.id;
                break;
            }
        }

        let dropElement;
        let dropBlock;
        let dropBlockIndex;
        let dropTargetBounds;

        if (!activeCustomDropTargetId) {
            const targetTextElements = sourceElement.canvas.getElementsByType("TextElement", true)
                .map(element => {
                    if (element.blockStructure === BlockStructureType.SINGLE_BLOCK) {
                        return {};
                    }

                    if (element !== sourceElement && sourceBlocks.some(block => !element.allowedBlockTypes.includes(block.model.type === "text" ? block.model.textStyle : block.model.type))) {
                        return {};
                    }

                    const blockContainer = element.blockContainerRef.current;

                    const blocksBounds = blockContainer.blocks.reduce((blocksBounds, block) => blocksBounds ? blocksBounds.union(block.bounds) : block.bounds, null);
                    const dragCenterPoint = dragPoint.offset(blockContainerBounds.width / 2, 0);

                    const dropBounds = blocksBounds.inflate({ left: 30, right: 30, top: 200, bottom: 200 });
                    if (!dropBounds.contains(dragCenterPoint)) {
                        return {};
                    }

                    const distance = blocksBounds.distanceFromBorderToPoint(dragCenterPoint);
                    return { element, distance };
                })
                .filter(({ element }) => !!element)
                .sort((a, b) => a.distance - b.distance)
                .map(({ element }) => element)
                .filter(element => element.isVisible);

            for (const element of targetTextElements) {
                if (dropElement) {
                    break;
                }

                const blockContainer = element.blockContainerRef.current;

                let isOverTopBlock = false;
                dropBlock = blockContainer.blocks.find(block => {
                    if (block.props.spansColumns) {
                        return false;
                    }

                    if (block.index === 0) {
                        if (dragPoint.y < block.bounds.top) {
                            isOverTopBlock = true;
                            return true;
                        }
                    }

                    if (blockContainer.props.columns > 1) {
                        const blockBounds = block.bounds.inflate({ bottom: blockContainer.props.gap });
                        const draggingBounds = new geom.Rect(dragPoint.x, dragPoint.y, updatedBlockContainerBounds.width, updatedBlockContainerBounds.height);
                        return draggingBounds.intersects(blockBounds);
                    } else {
                        const prevBlock = blockContainer.blocks[block.index - 1];
                        const nextBlock = blockContainer.blocks[block.index + 1];

                        if (nextBlock && prevBlock) {
                            return dragPoint.y < nextBlock.bounds.top && dragPoint.y > prevBlock.bounds.bottom;
                        } else if (nextBlock) {
                            return dragPoint.y < nextBlock.bounds.top;
                        } else if (prevBlock) {
                            return dragPoint.y > prevBlock.bounds.bottom;
                        } else {
                            return dragPoint.y > block.bounds.top;
                        }
                    }
                });

                if (dropBlock) {
                    dropElement = element;

                    let blockSpacing = 0;
                    if (!isOverTopBlock) {
                        const nextBlock = blockContainer.blocks[dropBlock.index + 1];
                        if (nextBlock) {
                            blockSpacing = (nextBlock.bounds.top - dropBlock.bounds.bottom) / 2;
                        } else {
                            blockSpacing += 10;
                        }
                    }

                    dropTargetBounds = dropBlock.bounds.offset(0, blockSpacing);
                    if (isOverTopBlock) {
                        dropBlockIndex = -1;
                        dropTargetBounds.top -= dropTargetBounds.height + 10;
                    } else if (dropBlock.index < sourceBlocks[0].index) {
                        dropBlockIndex = dropBlock.index + 1;
                    } else {
                        dropBlockIndex = dropBlock.index;
                    }
                    dropTargetBounds = dropTargetBounds.offset(-containerBounds.left, -containerBounds.top);
                }
            }
        }

        this.setState({
            blockContainerBounds: updatedBlockContainerBounds,
            activeCustomDropTargetId,
            dropTargetBounds,
            dropElement,
            dropBlock,
            dropBlockIndex
        });
    }

    handleDragEnd = event => {
        const {
            dragState: {
                sourceAuthoringBlockEditor,
                sourceElement,
                sourceBlocks,
            },
            selectionLayerController
        } = this.props;
        let {
            customDropTargets,
            dropElement,
            dropBlock,
            dropBlockIndex,
            activeCustomDropTargetId
        } = this.state;

        document.removeEventListener("mousemove", this.handleDrag);
        document.removeEventListener("mouseup", this.handleDragEnd);

        app.isDragging = false;
        app.isDraggingItem = false;

        sourceAuthoringBlockEditor.setState({ isDragging: false });

        for (const block of sourceBlocks) {
            block.setState({ isDragging: false });
        }

        this.setState({ ...DragBlockManagerRollover.defaulDragState });

        selectionLayerController.unregisterBlockDrag();

        if (!dropElement && !activeCustomDropTargetId) {
            return;
        }

        // when dragging a block within a textList, we need to turn off autoDistributeBullets
        if (sourceElement.getRootElement().isInstanceOf("TextList")) {
            sourceElement.getRootElement().model.autoDistributeBullets = false;
        }

        (async () => {
            if (activeCustomDropTargetId) {
                const firstBlock = sourceBlocks[0];
                const sourceBlockContainer = sourceElement.blockContainerRef.current;
                sourceBlockContainer.model.blocks.splice(firstBlock.index, sourceBlocks.length);

                const onDrop = customDropTargets.find(({ id }) => id === activeCustomDropTargetId).onDrop;
                await onDrop(sourceBlocks);

                return;
            }

            if (dropElement === sourceElement) {
                const blockContainer = sourceElement.blockContainerRef.current;

                if (dropBlockIndex === -1) {
                    dropBlockIndex = 0;
                }

                const targetBlock = blockContainer.blocks.find(block => block.index === dropBlockIndex);

                const firstBlock = sourceBlocks[0];
                if (dropBlockIndex > firstBlock.index) {
                    dropBlockIndex -= sourceBlocks.length - 1;
                }

                if (targetBlock) {
                    for (const block of sourceBlocks) {
                        block.model.blockStyle = targetBlock.model.blockStyle;
                        block.model.blockColor = targetBlock.model.blockColor;
                    }
                }

                if (dropBlockIndex === 0) {
                    sourceBlocks[0].model.indent = 0;
                }

                blockContainer.model.blocks.splice(firstBlock.index, sourceBlocks.length);
                blockContainer.model.blocks.splice(dropBlockIndex, 0, ...sourceBlocks.map(block => block.model));

                await new Promise(resolve => sourceAuthoringBlockEditor.setState({
                    focusedBlock: null,
                    rolloverBlock: null,
                    selectedBlocks: []
                }, resolve));

                await sourceElement.canvas.refreshCanvas({ suppressRefreshCanvasEvent: false });
                await selectionLayerController.selectTextElementBlock(dropElement, blockContainer.model.blocks[dropBlockIndex].id);
            } else {
                const firstBlock = sourceBlocks[0];

                const sourceBlockContainer = sourceElement.blockContainerRef.current;
                sourceBlockContainer.model.blocks.splice(firstBlock.index, sourceBlocks.length);

                if (dropBlockIndex === -1) {
                    dropBlockIndex = 0;
                } else if (dropBlock.index >= sourceBlocks[0].index) {
                    dropBlockIndex++;
                }

                if (dropBlockIndex === 0) {
                    sourceBlocks[0].model.indent = 0;
                }

                const dropBlockContainer = dropElement.blockContainerRef.current;
                dropBlockContainer.model.blocks.splice(dropBlockIndex, 0, ...sourceBlocks.map(block => ({ ..._.cloneDeep(block.model), id: uuid() })));

                sourceElement.parentElement.onBlockRemoved && sourceElement.parentElement.onBlockRemoved();

                await sourceElement.canvas.refreshCanvas({ suppressRefreshCanvasEvent: false });
                await selectionLayerController.selectTextElementBlock(dropElement, dropBlockContainer.model.blocks[dropBlockIndex].id);
            }

            await sourceElement.canvas.saveCanvasModel();
        })();
    }

    render() {
        const { canvasController } = this.props;
        const {
            isDragging,
            draggingBlocks,
            blockContainerBounds,
            dropTargetBounds,
            customDropTargets,
            activeCustomDropTargetId
        } = this.state;

        return (<Container ref={this.containerRef} isDragging={isDragging}>
            {isDragging && <>
                {dropTargetBounds && <TextElementDropTarget bounds={dropTargetBounds} />}
                {customDropTargets.map(({ id, bounds, labelText }) => (
                    <CustomDropTarget
                        key={id}
                        bounds={bounds}
                        labelText={labelText}
                        isActive={id === activeCustomDropTargetId}
                    />
                ))}
                <BlockContainer bounds={blockContainerBounds} boundsScale={canvasController.canvas.getScale()}>
                    {draggingBlocks.map(({ frameBounds, blockHTML, isRolloverBlock }, idx) => (<Fragment key={idx}>
                        <BlockFrame bounds={frameBounds} dangerouslySetInnerHTML={{ __html: blockHTML }} />
                        {isRolloverBlock && <MenuWidgetBox blockFrameBounds={frameBounds}>
                            <Icon>drag_indicator</Icon>
                        </MenuWidgetBox>}
                    </Fragment>)
                    )}
                </BlockContainer>
            </>
            }
        </Container>);
    }
}
